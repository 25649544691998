// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { MemberActions, MemberActionTypes } from '../_actions/member.actions';
// Models
import { MemberModel } from '../_models/member.model';
import { QueryParamsModel } from '../../_base/crud';

export interface MembersState extends EntityState<MemberModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedMemberId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<MemberModel> = createEntityAdapter<MemberModel>();

export const initialMembersState: MembersState = adapter.getInitialState({
    MemberForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedMemberId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function MembersReducer(state = initialMembersState, action: MemberActions): MembersState {
    //console.log("=============================>",action.type)
    switch  (action.type) {
        case MemberActionTypes.MembersPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedMemberId: undefined
            };
        }
        
        case MemberActionTypes.MemberActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case MemberActionTypes.MemberOnServerCreated: return {
            ...state
        };
        // case MemberActionTypes.MemberCreated: return adapter.addOne(action.payload.Member, {
        //     ...state, lastCreatedMemberId: action.payload.Member.id
        // });
        case MemberActionTypes.MemberUpdated: return adapter.updateOne(action.payload.partialMember, state);
        case MemberActionTypes.MembersStatusUpdated: {
            const _partialMembers: Update<MemberModel>[] = [];
            // tslint:disable-next-line:prefer-const
            //for (let i = 0; i < action.payload.Members.length; i++) {
                _partialMembers.push({
				    id: action.payload.Members,
				    changes: {
                        active: action.payload.status
                    }
			    });
            //}
            return adapter.updateMany(_partialMembers, state);
        }
        case MemberActionTypes.OneMemberDeleted: return adapter.removeOne(action.payload.id, state);
        case MemberActionTypes.ManyMembersDeleted: return adapter.removeMany(action.payload.ids, state);
        case MemberActionTypes.MembersPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case MemberActionTypes.MembersPageLoaded: {
            //console.log("checking now")
            return adapter.addMany(action.payload.Members, {
                ...initialMembersState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getMemberState = createFeatureSelector<MemberModel>('member');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
