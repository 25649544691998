// NGRX
import { Action } from '@ngrx/store';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { PartnerModel } from '../_models/partner.model';
import { Update } from '@ngrx/entity';

export enum PartnerActionTypes {
    PartnerOnServerCreated = '[Edit Partner Component] Partner On Server Created',
    PartnerCreated = '[Edit Partner Component] Partner Created',
    PartnerUpdated = '[Edit Partner Component] Partner Updated',
    PartnersStatusUpdated = '[Partners List Page] Partners Status Updated',
    OnePartnerDeleted = '[Partners List Page] One Partner Deleted',
    ManyPartnersDeleted = '[Partners List Page] Many Selected Partners Deleted',
    PartnersPageRequested = '[Partners List Page] Partners Page Requested',
    PartnersPageLoaded = '[Partners API] Partners Page Loaded',
    PartnersPageCancelled = '[Partners API] Partners Page Cancelled',
    PartnersPageToggleLoading = '[Partners] Partners Page Toggle Loading',
    PartnersActionToggleLoading = '[Partners] Partners Action Toggle Loading'
}

export class PartnerOnServerCreated implements Action {
    readonly type = PartnerActionTypes.PartnerOnServerCreated;
    constructor(public payload: { Partner: PartnerModel }) { }
}

export class PartnerCreated implements Action {
    readonly type = PartnerActionTypes.PartnerCreated;
    constructor(public payload: { partner: PartnerModel }) { }
}

export class PartnerUpdated implements Action {
    readonly type = PartnerActionTypes.PartnerUpdated;
    constructor(public payload: {
        partialPartner: Update<PartnerModel>, // For State update
        partner: PartnerModel // For Server update (through service)
    }) { }
}

export class PartnersStatusUpdated implements Action {
    readonly type = PartnerActionTypes.PartnersStatusUpdated;
    constructor(public payload: {
        partner: string,
        status: boolean
    }) { }
}

export class OnePartnerDeleted implements Action {
    readonly type = PartnerActionTypes.OnePartnerDeleted;
    constructor(public payload: { id: string }) {}
}

export class ManyPartnersDeleted implements Action {
    readonly type = PartnerActionTypes.ManyPartnersDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class PartnersPageRequested implements Action {
    readonly type = PartnerActionTypes.PartnersPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class PartnersPageLoaded implements Action {
    readonly type = PartnerActionTypes.PartnersPageLoaded;
    constructor(public payload: { partner: PartnerModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class PartnersPageCancelled implements Action {
    readonly type = PartnerActionTypes.PartnersPageCancelled;
}

export class PartnersPageToggleLoading implements Action {
    readonly type = PartnerActionTypes.PartnersPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class PartnersActionToggleLoading implements Action {
    readonly type = PartnerActionTypes.PartnersActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type PartnerActions = PartnerOnServerCreated
| PartnerCreated
| PartnerUpdated
| PartnersStatusUpdated
| OnePartnerDeleted
| ManyPartnersDeleted
| PartnersPageRequested
| PartnersPageLoaded
| PartnersPageCancelled
| PartnersPageToggleLoading
| PartnersActionToggleLoading;
