// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, delay } from 'rxjs/operators';
import { fromEvent, merge, Observable, of, Subscription } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
// UI
import { SubheaderService } from '../../../../../../core/_base/layout';
// CRUD
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../../../core/_base/crud';
// Services and Models
import {
	PartnerModel,
	PartnersDataSource,
	PartnersPageRequested,
	OnePartnerDeleted,
	ManyPartnersDeleted,
	PartnersStatusUpdated,
	selectPartnersPageLastQuery
} from '../../../../../../core/e-commerce';
import { TranslateService } from '@ngx-translate/core';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'kt-partner-list',
	templateUrl: './partner-list.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnersListComponent implements OnInit, OnDestroy {
	// Table fields
	dataSource: PartnersDataSource;
	displayedColumns = ['companyname','contactname','companylogo', 'email','phone','weburl','actions'];
	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild('sort1', {static: true}) sort: MatSort;
	// Filter fields
	@ViewChild('searchInput', {static: true}) searchInput: ElementRef;
	filterStatus = '';
	filterCondition = '';
	lastQuery: QueryParamsModel;
	// Selection
	selection = new SelectionModel<PartnerModel>(true, []);
	PartnersResult: PartnerModel[] = [];
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param dialog: MatDialog
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param store: Store<AppState>
	 */
	constructor(public dialog: MatDialog,
				   private activatedRoute: ActivatedRoute,
				   private translate: TranslateService,
		           private router: Router,
		           private subheaderService: SubheaderService,
		           private layoutUtilsService: LayoutUtilsService,
		           private store: Store<AppState>) { }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		// If the user changes the sort order, reset back to the first page.
		const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		this.subscriptions.push(sortSubscription);

		/* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
		const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
			tap(() => this.loadPartnersList())
		)
		.subscribe();
		this.subscriptions.push(paginatorSubscriptions);

		//Filtration, bind to searchInput
		const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
			debounceTime(150),
			distinctUntilChanged(),
			tap(() => {
				this.paginator.pageIndex = 0;
				this.loadPartnersList();
			})
		)
		.subscribe();
		this.subscriptions.push(searchSubscription);

		// Set title to page breadCrumbs
		this.subheaderService.setTitle('Partners');

		// Init DataSource
		this.dataSource = new PartnersDataSource(this.store);
		const entitiesSubscription = this.dataSource.entitySubject.pipe(
			skip(1),
			distinctUntilChanged()).subscribe(res => {
			this.PartnersResult = res;
		});
		this.subscriptions.push(entitiesSubscription);
		const lastQuerySubscription = this.store.pipe(select(selectPartnersPageLastQuery)).subscribe(res => this.lastQuery = res);
		// Load last query from store
		this.subscriptions.push(lastQuerySubscription);

		// Read from URL itemId, for restore previous state
		const routeSubscription = this.activatedRoute.queryParams.subscribe(params => {
			if (params.id) {
				this.restoreState(this.lastQuery, params.id);
			}

			// First load
			of(undefined).pipe(delay(1000)).subscribe(() => { // Remove this line, just loading imitation
				this.loadPartnersList();
			}); // Remove this line, just loading imitation
		});
		this.subscriptions.push(routeSubscription);
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	/**
	 * Load Partners List
	 */
	loadPartnersList() {
		this.selection.clear();
		const queryParams = new QueryParamsModel(
			this.filterConfiguration(),
			this.sort.direction,
			this.sort.active,
			this.paginator.pageIndex,
			this.paginator.pageSize,
			
		);
		// Call request from server
		this.store.dispatch(new PartnersPageRequested({ page: queryParams }));
		this.selection.clear();
	}

	/**
	 * Returns object for filter
	 */
	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;
		filter.keyWord = searchText;
		return filter;
	}

	/**
	 * Restore state
	 *
	 * @param queryParams: QueryParamsModel
	 * @param id: number
	 */
	restoreState(queryParams: QueryParamsModel, id: number) {

		if (!queryParams.filter) {
			return;
		}

		if ('condition' in queryParams.filter) {
			this.filterCondition = queryParams.filter.condition.toString();
		}

		if ('status' in queryParams.filter) {
			this.filterStatus = queryParams.filter.status.toString();
		}

		if (queryParams.filter.model) {
			this.searchInput.nativeElement.value = queryParams.filter.model;
		}
	}

	
	/**
	 * Update status dialog
	 */
	updateStatusForImage(_item: PartnerModel) {
		const _title = 'Update status for selected Business';
		const _updateMessage = 'Status has been updated for selected Business';
		const _statuses = [{ value: false, text: 'Active' }, { value: true, text: 'Block' }];
		const _messages = [];

		
		_messages.push({
			text: `${_item.companyname}`,
			id: _item.id.toString(),
			status: _item.active,
			//statusTitle: this.getItemStatusString(_item.active),
			//statusCssClass: this.getItemCssClassByStatus(_item.isblock)
		});

		const dialogRef = this.layoutUtilsService.fetchElements(_item);
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				this.selection.clear();
				return;
			}

			this.store.dispatch(new PartnersStatusUpdated({
				status: res,
				partner: _item.id
			}));
			this.loadPartnersList()
			this.layoutUtilsService.showActionNotification(_updateMessage, MessageType.Update);
			this.selection.clear();
		});
	}


	deletePartner(_item: PartnerModel) {
		const _title: string = this.translate.instant('Delete Affiliate Partner!');
		const _description: string = this.translate.instant('Do you really want to delete this affiliate partner ? This process can not be undone.');
		const _waitDesciption: string = this.translate.instant('Deleting affiliate partner... ');
		const _deleteMessage = this.translate.instant('The affiliate partner is successfully deleted !');

		const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}

			this.store.dispatch(new OnePartnerDeleted({ id: _item.id }));
			this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
		});
	}


	/**
	 * Redirect to edit page
	 *
	 * @param id: any
	 */
	editPartner(id) {
		//this.router.navigate(['../Partners/edit', id], { relativeTo: this.activatedRoute });
		this.router.navigateByUrl('/material/form-controls/manage-affiliate/'+id);
	}

	createPartner() {
		this.router.navigateByUrl('/material/form-controls/manage-affiliate/add');
	}

	/**
	 * Check all rows are selected
	 */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.PartnersResult.length;
		return numSelected === numRows;
	}

	/**
	 * Selects all rows if they are not all selected; otherwise clear selection
	 */
	masterToggle() {
		if (this.isAllSelected()) {
			this.selection.clear();
		} else {
			this.PartnersResult.forEach(row => this.selection.select(row));
		}
	}

	/* UI */
	/**
	 * Returns status string
	 *
	 * @param status: number
	 */
	getItemStatusString(status: boolean = false): string {
		switch (status) {
			case false:
				return 'Active';
			case true:
				return 'Blocked';
		}
		return '';
	}

	/**
	 * Returns CSS Class by status
	 *
	 * @param status: number
	 */
	getItemCssClassByStatus(status: boolean = false): string {
		switch (status) {
			case false:
				return 'success';
			case true:
				return 'danger';
		}
		return '';
	}

	/**
	 * Rerurns condition string
	 *
	 * @param condition: number
	 */
	getItemConditionString(condition: number = 0): string {
		switch (condition) {
			case 0:
				return 'New';
			case 1:
				return 'Used';
		}
		return '';
	}

	/**
	 * Returns CSS Class by condition
	 *
	 * @param condition: number
	 */
	getItemCssClassByCondition(condition: number = 0): string {
		switch (condition) {
			case 0:
				return 'accent';
			case 1:
				return 'primary';
		}
		return '';
	}
}
