// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
// Models
import { PartnerModel } from '../_models/partner.model';
import { environment } from '../../../../environments/environment';
const API_Partner_URL = environment.API_URL
// Real REST API
@Injectable()
export class PartnerService {
	lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(new QueryParamsModel({}, 'asc', '', 0, 10));

	constructor(private http: HttpClient,
		           private httpUtils: HttpUtilsService) { }

	// CREATE =>  POST: add a new Partner to the server
	createPartner(Partner): Observable<PartnerModel> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<PartnerModel>(API_Partner_URL, Partner, { headers: httpHeaders });
	}

	// READ
	getAllPartner(): Observable<PartnerModel[]> {
		return this.http.get<PartnerModel[]>(API_Partner_URL);
	}

	getPartnerById(PartnerId: number): Observable<PartnerModel> {
		//return this.http.get<PartnerModel>(API_Partner_URL + `/${PartnerId}`);

		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
          };
		const httpParams = {"userId": `${PartnerId}`}

		const url = API_Partner_URL + 'user/get';
		return this.http.post<PartnerModel>(url , httpParams , httpOptions)
	}

	// Server should return filtered/sorted result
	findPartner(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
		  };
		const httpParams = queryParams

		const url = API_Partner_URL + 'affiliate/admin/list';
		return this.http.post<QueryResultsModel>(url , httpParams , httpOptions)
	}

	// UPDATE => PUT: update the Partner on the server
	updatePartner(Partner: PartnerModel): Observable<any> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.put(API_Partner_URL, Partner, { headers: httpHeaders });
	}

	// UPDATE Status
	// Comment this when you start work with real server
	// This code imitates server calls
	updateStatusForPartner(Partner: string, status: boolean): Observable<any> {
		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
          };
		const body = {
			userId: Partner,
			isBlock: status
		};
		const url = API_Partner_URL + 'user/admin/blockUnBlock';
		return this.http.post(url, body, httpOptions);
	}

	// DELETE => delete the Partner from the server
	// deletePartner(PartnerId: number): Observable<PartnerModel> {
	// 	const url = `${API_Partner_URL}/${PartnerId}`;
	// 	return this.http.delete<PartnerModel>(url);
	// }

	deletePartner(customerId: string): Observable<PartnerModel> {

		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
          };
		const body = {
			id: customerId,
		};
		const url = `${API_Partner_URL}`+'affiliate/admin/delete';
		return this.http.post<PartnerModel>(url , body , httpOptions);
	}

	deletePartners(ids: number[] = []): Observable<any> {
		const url = API_Partner_URL + '/delete';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { prdocutIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
	}
}
