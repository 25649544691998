// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
// Models
import { TagsModel } from '../_models/Tags.model';
import { environment } from '../../../../environments/environment';
const API_Tags_URL = environment.API_URL
// Real REST API
@Injectable()
export class TagsService {
	lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(new QueryParamsModel({}, 'asc', '', 0, 10));

	constructor(private http: HttpClient,
		           private httpUtils: HttpUtilsService) { }

	// CREATE =>  POST: add a new Tags to the server
	createTags(Tags): Observable<TagsModel> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<TagsModel>(API_Tags_URL, Tags, { headers: httpHeaders });
	}

	// READ
	getAllTags(): Observable<TagsModel[]> {
		return this.http.get<TagsModel[]>(API_Tags_URL);
	}

	getTagsById(TagsId: number): Observable<TagsModel> {
		//return this.http.get<TagsModel>(API_Tags_URL + `/${TagsId}`);

		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
          };
		const httpParams = {"userId": `${TagsId}`}

		const url = API_Tags_URL + 'user/get';
		return this.http.post<TagsModel>(url , httpParams , httpOptions)
	}

	// Server should return filtered/sorted result
	findTags(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
		  };
		const httpParams = queryParams

		const url = API_Tags_URL + 'Tags/admin/list';
		return this.http.post<QueryResultsModel>(url , httpParams , httpOptions)
	}

	// UPDATE => PUT: update the Tags on the server
	updateTags(Tags: TagsModel): Observable<any> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.put(API_Tags_URL, Tags, { headers: httpHeaders });
	}

	// UPDATE Status
	// Comment this when you start work with real server
	// This code imitates server calls
	updateStatusForTags(Tags: string, status: boolean): Observable<any> {
		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
          };
		const body = {
			userId: Tags,
			isBlock: status
		};
		const url = API_Tags_URL + 'user/admin/blockUnBlock';
		return this.http.post(url, body, httpOptions);
	}

	deleteEvent(customerId: string): Observable<TagsModel> {

		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
          };
		const body = {
			id: customerId,
		};
		const url = `${API_Tags_URL}`+'Tags/admin/delete';
		return this.http.post<TagsModel>(url , body , httpOptions);
	}

	deleteTags(ids: number[] = []): Observable<any> {
		const url = API_Tags_URL + '/delete';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { prdocutIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
	}
}
