import { BaseModel } from '../../_base/crud';

export class OrderModel extends BaseModel {

	id:string
	name:string
	status:boolean
	active:boolean
	clear() {

		this.id = ''
		this.name = ''
		this.status = false
		this.active = false
	}
}





