import { BaseModel } from '../../_base/crud';

export class PartnerModel extends BaseModel {

	active: boolean
	companylogo : string
	companyname:string
	contactname:string
	email:string
	id:string
	phone:string
	status:Number
	weburl:string


	clear() {

		this.active = false
		this.companylogo = ''
		this.companyname = ''
		this.contactname = ''
		this.email = ''
		this.id = ''
		this.phone =''
		this.weburl = ''
		this.status = 0
		
	}
}





