
// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { BNActions, BNActionTypes } from '../_actions/BN.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { BNModel } from '../_models/BN.model';

export interface BNState extends EntityState<BNModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastQuery: QueryParamsModel;
    lastCreatedBNId: number;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<BNModel> = createEntityAdapter<BNModel>();

export const initialBNState: BNState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel({}),
    lastCreatedBNId: undefined,
    showInitWaitingMessage: true
});

export function BNReducer(state = initialBNState, action: BNActions): BNState {

    //console.log('Hi i am inside the tag reducer',initialBNState)
    switch  (action.type) {
        case BNActionTypes.BNPageToggleLoading: return {
            ...state, listLoading: action.payload.isLoading, lastCreatedBNId: undefined
        };
        case BNActionTypes.BNActionToggleLoading: return {
            ...state, actionsloading: action.payload.isLoading
        };
        case BNActionTypes.BNOnServerCreated: return {
            ...state
        };
        // case BNActionTypes.BNCreated: return adapter.addOne(action.payload.BN, {
        //      ...state, lastCreatedBNId: action.payload.BN.id
        // });
        case BNActionTypes.BNUpdated: return adapter.updateOne(action.payload.partialBN, state);
       
        case BNActionTypes.OneBNDeleted: return adapter.removeOne(action.payload.id, state);
        case BNActionTypes.ManyBNDeleted: return adapter.removeMany(action.payload.ids, state);
        case BNActionTypes.BNPageCancelled: return {
            ...state, listLoading: false, lastQuery: new QueryParamsModel({})
        };
        case BNActionTypes.BNPageLoaded:
            return adapter.addMany(action.payload.BN, {
                ...initialBNState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        default: return state;
    }
}

export const getBNtate = createFeatureSelector<BNModel>('BN');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
