// NGRX
import { Action } from '@ngrx/store';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { BNModel } from '../_models/BN.model';
import { Update } from '@ngrx/entity';

export enum BNActionTypes {
    BNOnServerCreated = '[Edit BN Component] BN On Server Created',
    BNCreated = '[Edit BN Component] BN Created',
    BNUpdated = '[Edit BN Component] BN Updated',
    BNStatusUpdated = '[BN List Page] BN Status Updated',
    OneBNDeleted = '[BN List Page] One BN Deleted',
    ManyBNDeleted = '[BN List Page] Many Selected BN Deleted',
    BNPageRequested = '[BN List Page] BN Page Requested',
    BNPageLoaded = '[BN API] BN Page Loaded',
    BNPageCancelled = '[BN API] BN Page Cancelled',
    BNPageToggleLoading = '[BN] BN Page Toggle Loading',
    BNActionToggleLoading = '[BN] BN Action Toggle Loading'
}

export class BNOnServerCreated implements Action {
    readonly type = BNActionTypes.BNOnServerCreated;
    constructor(public payload: { BN: BNModel }) { }
}

export class BNCreated implements Action {
    readonly type = BNActionTypes.BNCreated;
    constructor(public payload: { BN: BNModel }) { }
}

export class BNUpdated implements Action {
    readonly type = BNActionTypes.BNUpdated;
    constructor(public payload: {
        partialBN: Update<BNModel>, // For State update
        BN: BNModel // For Server update (through service)
    }) { }
}

export class BNStatusUpdated implements Action {
    readonly type = BNActionTypes.BNStatusUpdated;
    constructor(public payload: {
        BN: string,
        status: boolean
    }) { }
}

export class OneBNDeleted implements Action {
    readonly type = BNActionTypes.OneBNDeleted;
    constructor(public payload: { id: string }) {}
}

export class ManyBNDeleted implements Action {
    readonly type = BNActionTypes.ManyBNDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class BNPageRequested implements Action {
    readonly type = BNActionTypes.BNPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class BNPageLoaded implements Action {
    readonly type = BNActionTypes.BNPageLoaded;
    constructor(public payload: { BN: BNModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class BNPageCancelled implements Action {
    readonly type = BNActionTypes.BNPageCancelled;
}

export class BNPageToggleLoading implements Action {
    readonly type = BNActionTypes.BNPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class BNActionToggleLoading implements Action {
    readonly type = BNActionTypes.BNActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type BNActions = BNOnServerCreated
| BNCreated
| BNUpdated
| BNStatusUpdated
| OneBNDeleted
| ManyBNDeleted
| BNPageRequested
| BNPageLoaded
| BNPageCancelled
| BNPageToggleLoading
| BNActionToggleLoading;
