// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { BNState } from '../_reducers/BN.reducers';
import { BNModel } from '../_models/BN.model';

export const selectBNState = createFeatureSelector<BNState>('bn');

export const selectBNById = (productId: string) => createSelector(
    selectBNState,
    BNState => BNState.entities[productId]
);

export const selectBNPageLoading = createSelector(
    selectBNState,
    BNState => BNState.listLoading
);

export const selectBNActionLoading = createSelector(
    selectBNState,
    customersState => customersState.actionsloading
);

export const selectBNPageLastQuery = createSelector(
    selectBNState,
    BNState => BNState.lastQuery
);

export const selectLastCreatedBNId = createSelector(
    selectBNState,
    BNState => BNState.lastCreatedBNId
);

export const selectBNInitWaitingMessage = createSelector(
    selectBNState,
    BNState => BNState.showInitWaitingMessage
);

export const selectBNInStore = createSelector(
    selectBNState,
    BNState => {
        //console.log('The tag state is',BNState,selectBNState)
        const items: BNModel[] = [];
        each(BNState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        //const result: ProductModel[] = httpExtension.sortArray(items, BNState.lastQuery.sortField, BNState.lastQuery.sortOrder);
        return new QueryResultsModel(items, BNState.totalCount, '');
    }
);

export const selectHasBNInStore = createSelector(
    selectBNInStore,
    queryResult => {
        if (!queryResult.resultCount) {
            return false;
        }

        return true;
    }
);
