// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
// Models
import { EventsModel } from '../_models/events.model';
import { environment } from '../../../../environments/environment';
const API_Events_URL = environment.API_URL
// Real REST API
@Injectable()
export class EventsService {
	lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(new QueryParamsModel({}, 'asc', '', 0, 10));

	constructor(private http: HttpClient,
		           private httpUtils: HttpUtilsService) { }

	// CREATE =>  POST: add a new Events to the server
	createEvents(Events): Observable<EventsModel> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<EventsModel>(API_Events_URL, Events, { headers: httpHeaders });
	}

	// READ
	getAllEvents(): Observable<EventsModel[]> {
		return this.http.get<EventsModel[]>(API_Events_URL);
	}

	getEventsById(EventsId: number): Observable<EventsModel> {
		//return this.http.get<EventsModel>(API_Events_URL + `/${EventsId}`);

		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
          };
		const httpParams = {"userId": `${EventsId}`}

		const url = API_Events_URL + 'user/get';
		return this.http.post<EventsModel>(url , httpParams , httpOptions)
	}

	// Server should return filtered/sorted result
	findEvents(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
		  };
		const httpParams = queryParams

		const url = API_Events_URL + 'events/admin/list';
		return this.http.post<QueryResultsModel>(url , httpParams , httpOptions)
	}

	// UPDATE => PUT: update the Events on the server
	updateEvents(Events: EventsModel): Observable<any> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.put(API_Events_URL, Events, { headers: httpHeaders });
	}

	// UPDATE Status
	// Comment this when you start work with real server
	// This code imitates server calls
	updateStatusForEvents(Events: string, status: boolean): Observable<any> {
		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
          };
		const body = {
			userId: Events,
			isBlock: status
		};
		const url = API_Events_URL + 'user/admin/blockUnBlock';
		return this.http.post(url, body, httpOptions);
	}

	deleteEvent(customerId: string): Observable<EventsModel> {

		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
          };
		const body = {
			id: customerId,
		};
		const url = `${API_Events_URL}`+'events/admin/delete';
		return this.http.post<EventsModel>(url , body , httpOptions);
	}

	deleteEvents(ids: number[] = []): Observable<any> {
		const url = API_Events_URL + '/delete';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { prdocutIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
	}
}
