import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
// CRUD
import { QueryResultsModel, QueryParamsModel } from '../../_base/crud';
// Services
import { BNService } from '../_services';
// State
import { AppState } from '../../reducers';
// Actions
import {
    BNActionTypes,
    BNPageRequested,
    BNPageLoaded,
    ManyBNDeleted,
    OneBNDeleted,
    BNPageToggleLoading,
    BNStatusUpdated,
    BNUpdated,
    BNCreated,
    BNOnServerCreated
} from '../_actions/BN.actions';
import { defer, Observable, of } from 'rxjs';

@Injectable()
export class BNEffects {
    showPageLoadingDistpatcher = new BNPageToggleLoading({ isLoading: true });
    showLoadingDistpatcher = new BNPageToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new BNPageToggleLoading({ isLoading: false });

    @Effect()
    loadBNsPage$ = this.actions$
        .pipe(
            ofType<BNPageRequested>(BNActionTypes.BNPageRequested),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showPageLoadingDistpatcher);
                const requestToServer = this.BNService.findBN(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {         
                    
                const result: QueryResultsModel = response[0].output;
                const lastQuery: QueryParamsModel = response[1];
                return new BNPageLoaded({
                    BN: result.list,
                    totalCount: result.resultCount,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteEvent$ = this.actions$
        .pipe(
            ofType<OneBNDeleted>(BNActionTypes.OneBNDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showLoadingDistpatcher);
                    return this.BNService.deleteEvent(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteBN$ = this.actions$
        .pipe(
            ofType<ManyBNDeleted>(BNActionTypes.ManyBNDeleted),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.BNService.deleteBN(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateBNStatus$ = this.actions$
        .pipe(
            ofType<BNStatusUpdated>(BNActionTypes.BNStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.BNService.updateStatusForBN(payload.BN, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateBN$ = this.actions$
        .pipe(
            ofType<BNUpdated>(BNActionTypes.BNUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.BNService.updateBN(payload.BN);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    createBN$ = this.actions$
        .pipe(
            ofType<BNOnServerCreated>(BNActionTypes.BNOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.BNService.createBN(payload.BN).pipe(
                    tap(res => {
                        this.store.dispatch(new BNCreated({ BN: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    // @Effect()
    // init$: Observable<Action> = defer(() => {
    //     const queryParams = new QueryParamsModel({});
    //     return of(new BNsPageRequested({ page: queryParams }));
    // });

    constructor(private actions$: Actions, private BNService: BNService, private store: Store<AppState>) { }
}
