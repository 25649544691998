
// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { TagsActions, TagsActionTypes } from '../_actions/tags.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { TagsModel } from '../_models/Tags.model';

export interface TagsState extends EntityState<TagsModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastQuery: QueryParamsModel;
    lastCreatedTagsId: number;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<TagsModel> = createEntityAdapter<TagsModel>();

export const initialTagsState: TagsState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel({}),
    lastCreatedTagsId: undefined,
    showInitWaitingMessage: true
});

export function TagsReducer(state = initialTagsState, action: TagsActions): TagsState {

    //console.log('Hi i am inside the tag reducer',initialTagsState)
    switch  (action.type) {
        case TagsActionTypes.TagsPageToggleLoading: return {
            ...state, listLoading: action.payload.isLoading, lastCreatedTagsId: undefined
        };
        case TagsActionTypes.TagsActionToggleLoading: return {
            ...state, actionsloading: action.payload.isLoading
        };
        case TagsActionTypes.TagsOnServerCreated: return {
            ...state
        };
        // case TagsActionTypes.TagsCreated: return adapter.addOne(action.payload.Tags, {
        //      ...state, lastCreatedTagsId: action.payload.Tags.id
        // });
        case TagsActionTypes.TagsUpdated: return adapter.updateOne(action.payload.partialTags, state);
       
        case TagsActionTypes.OneTagsDeleted: return adapter.removeOne(action.payload.id, state);
        case TagsActionTypes.ManyTagsDeleted: return adapter.removeMany(action.payload.ids, state);
        case TagsActionTypes.TagsPageCancelled: return {
            ...state, listLoading: false, lastQuery: new QueryParamsModel({})
        };
        case TagsActionTypes.TagsPageLoaded:
            return adapter.addMany(action.payload.Tags, {
                ...initialTagsState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        default: return state;
    }
}

export const getTagstate = createFeatureSelector<TagsModel>('Tags');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
