export class MenuConfig {
	public defaults: any = {
		aside: {
			self: {},
			items: [
				{
					title: "Reports",
					root: true,
					icon: "flaticon2-architecture-and-city",
					page: "/dashboard",
					translate: "MENU.DASHBOARD",
					bullet: "dot",
				},
												
				{
					title: "Manage Users",
					page: "/ecao/users",
					root: true,
					icon: "flaticon2-browser-2",
					translate: "MENU.DASHBOARD",
					bullet: "dot",
				},
				{
					title: "Manage Content Category",
					page: "/ecao/categories",
					root: true,
					icon: "flaticon2-architecture-and-city",
					translate: "MENU.DASHBOARD",
					bullet: "dot",
				},
				{
					title: "Manage Content",
					page: "/ecao/contents",
					root: true,
					icon: "flaticon2-digital-marketing",
					translate: "MENU.DASHBOARD",
					bullet: "dot",
				},
				{
					title: "Manage Member Resource",
					page: "/ecao/member",
					root: true,
					icon: "flaticon2-digital-marketing",
					translate: "MENU.DASHBOARD",
					bullet: "dot",
				},
				{
					title: "Manage Events",
					page:  "/ecao/events",
					root: true,
					icon: "flaticon2-architecture-and-city",
					translate: "MENU.DASHBOARD",
					bullet: "dot",
				},
				{
					title: "Manage Tags",
					root: true,
					icon: "flaticon2-tag",
					page: "/ecao/tags",
					translate: "MENU.DASHBOARD",
					bullet: "dot",
				},
				{
					title: "Manage Affiliate Partners",
					page: "/ecao/partner",
					root: true,
					icon: "flaticon2-user-outline-symbol",
					translate: "MENU.DASHBOARD",
					bullet: "dot",
				},
				{
					title: "Broadcast Notifications",
					root: true,
					icon: "flaticon-alert",
					page: "/material/form-controls/input",
					translate: "MENU.DASHBOARD",
					bullet: "dot",
				},
				{
					title: "Notifications History",
					root: true,
					icon: "flaticon2-list",
					page: "/ecao/bn",
					translate: "MENU.DASHBOARD",
					bullet: "dot",
				},

				// {
				// 	title: "Analytics",
				// 	root: true,
				// 	icon: "flaticon-alert",
				// 	page: "/material/form-controls/analytics",
				// 	translate: "MENU.DASHBOARD",
				// 	bullet: "dot",
				// },
			],
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
