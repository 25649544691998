// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { EventsState } from '../_reducers/events.reducers';
import { EventsModel } from '../_models/events.model';

export const selectEventsState = createFeatureSelector<EventsState>('events');

export const selectEventsById = (productId: string) => createSelector(
    selectEventsState,
    EventsState => EventsState.entities[productId]
);

export const selectEventsPageLoading = createSelector(
    selectEventsState,
    EventsState => EventsState.listLoading
);

export const selectEventsActionLoading = createSelector(
    selectEventsState,
    customersState => customersState.actionsloading
);

export const selectEventsPageLastQuery = createSelector(
    selectEventsState,
    EventsState => EventsState.lastQuery
);

export const selectLastCreatedEventsId = createSelector(
    selectEventsState,
    EventsState => EventsState.lastCreatedEventsId
);

export const selectEventsInitWaitingMessage = createSelector(
    selectEventsState,
    EventsState => EventsState.showInitWaitingMessage
);

export const selectEventsInStore = createSelector(
    selectEventsState,
    EventsState => {
        //console.log('The tag EventsState is',EventsState)
        const items: EventsModel[] = [];
        each(EventsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        //const result: ProductModel[] = httpExtension.sortArray(items, EventsState.lastQuery.sortField, EventsState.lastQuery.sortOrder);
        return new QueryResultsModel(items, EventsState.totalCount, '');
    }
);

export const selectHasEventsInStore = createSelector(
    selectEventsInStore,
    queryResult => {
        if (!queryResult.resultCount) {
            return false;
        }

        return true;
    }
);
