// Context
export { ECommerceDataContext } from './_server/_e-commerce.data-context';

// Models and Consts
export { CustomerModel } from './_models/customer.model';
export { MemberModel } from './_models/member.model';

export { ProductRemarkModel } from './_models/product-remark.model';
export { ProductSpecificationModel } from './_models/product-specification.model';
export { ProductModel } from './_models/product.model';
export { OrderModel } from './_models/order.model';
export { EventsModel } from './_models/events.model';
export { TagsModel } from './_models/tags.model';
export { BNModel } from './_models/bn.model';
export { PartnerModel } from './_models/partner.model';
export { SPECIFICATIONS_DICTIONARY } from './_consts/specification.dictionary';

// DataSources
export { CustomersDataSource } from './_data-sources/customers.datasource';
export { MemberDataSource } from './_data-sources/member.datasource';

export { ProductRemarksDataSource } from './_data-sources/product-remarks.datasource';
export { ProductSpecificationsDataSource } from './_data-sources/product-specifications.datasource';
export { ProductsDataSource } from './_data-sources/products.datasource';
export { EventsDataSource } from './_data-sources/events.datasource';
export { TagsDataSource } from './_data-sources/tags.datasource';
export { BNDataSource } from './_data-sources/bn.datasource';
export { PartnersDataSource } from './_data-sources/partner.datasource';
export { OrdersDataSource } from './_data-sources/order.datasource';

// Actions
// Customer Actions =>
export {
    CustomerActionTypes,
    CustomerActions,
    CustomerOnServerCreated,
    CustomerCreated,
    CustomerUpdated,
    CustomersStatusUpdated,
    OneCustomerDeleted,
    ManyCustomersDeleted,
    CustomersPageRequested,
    CustomersPageLoaded,
    CustomersPageCancelled,
    CustomersPageToggleLoading
} from './_actions/customer.actions';

// Actions
// Customer Actions =>
export {
    BNActionTypes,
    BNActions,
    BNOnServerCreated,
    BNCreated,
    BNUpdated,
    BNStatusUpdated,
    OneBNDeleted,
    ManyBNDeleted,
    BNPageRequested,
    BNPageLoaded,
    BNPageCancelled,
    BNPageToggleLoading
} from './_actions/bn.actions';

export {
    MemberActionTypes,
    MemberActions,
    MemberOnServerCreated,
    MemberCreated,
    MemberUpdated,
    MembersStatusUpdated,
    OneMemberDeleted,
    ManyMembersDeleted,
    MembersPageRequested,
    MembersPageLoaded,
    MembersPageCancelled,
    MembersPageToggleLoading
} from './_actions/member.actions';
// Product actions =>
export {
    ProductActionTypes,
    ProductActions,
    ProductOnServerCreated,
    ProductCreated,
    ProductUpdated,
    ProductsStatusUpdated,
    OneProductDeleted,
    ManyProductsDeleted,
    ProductsPageRequested,
    ProductsPageLoaded,
    ProductsPageCancelled,
    ProductsPageToggleLoading,
    ProductsActionToggleLoading
} from './_actions/product.actions';

export {
    PartnerActionTypes,
    PartnerActions,
    PartnerOnServerCreated,
    PartnerCreated,
    PartnerUpdated,
    PartnersStatusUpdated,
    OnePartnerDeleted,
    ManyPartnersDeleted,
    PartnersPageRequested,
    PartnersPageLoaded,
    PartnersPageCancelled,
    PartnersPageToggleLoading,
    PartnersActionToggleLoading
} from './_actions/partner.actions';

export {
    OrderActionTypes,
    OrderActions,
    OrderOnServerCreated,
    OrderCreated,
    OrderUpdated,
    OrdersStatusUpdated,
    OneOrderDeleted,
    ManyOrdersDeleted,
    OrdersPageRequested,
    OrdersPageLoaded,
    OrdersPageCancelled,
    OrdersPageToggleLoading,
    OrdersActionToggleLoading
} from './_actions/order.actions';

export {
    EventsActionTypes,
    EventsActions,
    EventsOnServerCreated,
    EventsCreated,
    EventsUpdated,
    EventsStatusUpdated,
    OneEventsDeleted,
    ManyEventsDeleted,
    EventsPageRequested,
    EventsPageLoaded,
    EventsPageCancelled,
    EventsPageToggleLoading,
    EventsActionToggleLoading
} from './_actions/events.actions';


export {
    TagsActionTypes,
    TagsActions,
    TagsOnServerCreated,
    TagsCreated,
    TagsUpdated,
    TagsStatusUpdated,
    OneTagsDeleted,
    ManyTagsDeleted,
    TagsPageRequested,
    TagsPageLoaded,
    TagsPageCancelled,
    TagsPageToggleLoading,
    TagsActionToggleLoading
} from './_actions/tags.actions';
// ProductRemark Actions =>
export {
    ProductRemarkActionTypes,
    ProductRemarkActions,
    ProductRemarkCreated,
    ProductRemarkUpdated,
    ProductRemarkStoreUpdated,
    OneProductRemarkDeleted,
    ManyProductRemarksDeleted,
    ProductRemarksPageRequested,
    ProductRemarksPageLoaded,
    ProductRemarksPageCancelled,
    ProductRemarksPageToggleLoading,
    ProductRemarkOnServerCreated
} from './_actions/product-remark.actions';
// ProductSpecification Actions =>
export {
    ProductSpecificationActionTypes,
    ProductSpecificationActions,
    ProductSpecificationCreated,
    ProductSpecificationUpdated,
    OneProductSpecificationDeleted,
    ManyProductSpecificationsDeleted,
    ProductSpecificationsPageRequested,
    ProductSpecificationsPageLoaded,
    ProductSpecificationsPageCancelled,
    ProductSpecificationsPageToggleLoading,
    ProductSpecificationOnServerCreated
} from './_actions/product-specification.actions';


// Effects
export { CustomerEffects } from './_effects/customer.effects';
export { MemberEffects } from './_effects/member.effects';
export { ProductEffects } from './_effects/product.effects';
export { PartnerEffects } from './_effects/partner.effects';
export { EventsEffects } from './_effects/events.effects';
export { TagsEffects } from './_effects/tags.effects';
export { BNEffects } from './_effects/bn.effects';
export { OrderEffects } from './_effects/order.effects';
export { ProductRemarkEffects } from './_effects/product-remark.effects';
export { ProductSpecificationEffects } from './_effects/product-specification.effects';

// Reducers
export { customersReducer } from './_reducers/customer.reducers';
export { MembersReducer } from './_reducers/member.reducers';
export { productsReducer } from './_reducers/product.reducers';
export { EventsReducer } from './_reducers/events.reducers';
export { TagsReducer } from './_reducers/tags.reducers';
export { BNReducer } from './_reducers/bn.reducers';
export { OrdersReducer } from './_reducers/order.reducers';
export { PartnersReducer } from './_reducers/partner.reducers';
export { productRemarksReducer } from './_reducers/product-remark.reducers';
export { productSpecificationsReducer } from './_reducers/product-specification.reducers';

// Selectors
// Customer selectors =>
export {
    selectCustomerById,
    selectCustomersInStore,
    selectCustomersPageLoading,
    selectLastCreatedCustomerId,
    selectCustomersActionLoading,
    selectCustomersShowInitWaitingMessage
} from './_selectors/customer.selectors';
export {
    selectBNById,
    selectBNInStore,
    selectBNPageLoading,
    selectBNPageLastQuery,
    selectLastCreatedBNId,
    selectHasBNInStore,
    selectBNActionLoading,
    selectBNInitWaitingMessage
} from './_selectors/bn.selectors';

export {
    selectMemberById,
    selectMembersInStore,
    selectMembersPageLoading,
    selectLastCreatedMemberId,
    selectMembersActionLoading,
    selectMembersShowInitWaitingMessage
} from './_selectors/member.selectors';


// Product selectors
export {
    selectProductById,
    selectProductsInStore,
    selectProductsPageLoading,
    selectProductsPageLastQuery,
    selectLastCreatedProductId,
    selectHasProductsInStore,
    selectProductsActionLoading,
    selectProductsInitWaitingMessage
} from './_selectors/product.selectors';

export {
    selectPartnerById,
    selectPartnersInStore,
    selectPartnersPageLoading,
    selectPartnersPageLastQuery,
    selectLastCreatedPartnerId,
    selectHasPartnersInStore,
    selectPartnersActionLoading,
    selectPartnersInitWaitingMessage
} from './_selectors/partner.selectors';

export {
    selectOrderById,
    selectOrdersInStore,
    selectOrdersPageLoading,
    selectOrdersPageLastQuery,
    selectLastCreatedOrderId,
    selectHasOrdersInStore,
    selectOrdersActionLoading,
    selectOrdersInitWaitingMessage
} from './_selectors/order.selectors';

export {
    selectEventsById,
    selectEventsInStore,
    selectEventsPageLoading,
    selectEventsPageLastQuery,
    selectLastCreatedEventsId,
    selectHasEventsInStore,
    selectEventsActionLoading,
    selectEventsInitWaitingMessage
} from './_selectors/events.selectors';
export {
    selectTagsById,
    selectTagsInStore,
    selectTagsPageLoading,
    selectTagsPageLastQuery,
    selectLastCreatedTagsId,
    selectHasTagsInStore,
    selectTagsActionLoading,
    selectTagsInitWaitingMessage
} from './_selectors/tags.selectors';
// ProductRemark selectors =>
export {
    selectProductRemarkById,
    selectProductRemarksInStore,
    selectProductRemarksPageLoading,
    selectCurrentProductIdInStoreForProductRemarks,
    selectLastCreatedProductRemarkId,
    selectPRShowInitWaitingMessage
} from './_selectors/product-remark.selectors';
// ProductSpecification selectors =>
export {
    selectProductSpecificationById,
    selectProductSpecificationsInStore,
    selectProductSpecificationsPageLoading,
    selectCurrentProductIdInStoreForProductSpecs,
    selectProductSpecificationsState,
    selectLastCreatedProductSpecificationId,
    selectPSShowInitWaitingMessage
} from './_selectors/product-specification.selectors';

// Services
export { CustomersService } from './_services/';
export { MemberService } from './_services/';

export { ProductsService } from './_services/';
export { PartnerService } from './_services/';
export { EventsService } from './_services/';
export { TagsService } from './_services/';
export { BNService } from './_services/';
export { OrderService } from './_services/';
export { ProductRemarksService } from './_services/';
export { ProductSpecificationsService } from './_services/';
