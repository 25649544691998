// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { PartnersState } from '../_reducers/partner.reducers';
import { PartnerModel } from '../_models/partner.model';

export const selectPartnersState = createFeatureSelector<PartnersState>('partner');

export const selectPartnerById = (PartnerId: string) => createSelector(
    selectPartnersState,
    PartnersState => PartnersState.entities[PartnerId]
);

export const selectPartnersPageLoading = createSelector(
    selectPartnersState,
    PartnersState => PartnersState.listLoading
);

export const selectPartnersActionLoading = createSelector(
    selectPartnersState,
    customersState => customersState.actionsloading
);

export const selectPartnersPageLastQuery = createSelector(
    selectPartnersState,
    PartnersState => PartnersState.lastQuery
);

export const selectLastCreatedPartnerId = createSelector(
    selectPartnersState,
    PartnersState => PartnersState.lastCreatedPartnerId
);

export const selectPartnersInitWaitingMessage = createSelector(
    selectPartnersState,
    PartnersState => PartnersState.showInitWaitingMessage
);

export const selectPartnersInStore = createSelector(
    selectPartnersState,
    PartnersState => {
        const items: PartnerModel[] = [];
        each(PartnersState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        //const result: PartnerModel[] = httpExtension.sortArray(items, PartnersState.lastQuery.sortField, PartnersState.lastQuery.sortOrder);
        return new QueryResultsModel(items, PartnersState.totalCount, '');
    }
);

export const selectHasPartnersInStore = createSelector(
    selectPartnersInStore,
    queryResult => {
        if (!queryResult.resultCount) {
            return false;
        }

        return true;
    }
);
