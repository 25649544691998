// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { MembersState } from '../_reducers/member.reducers';
import { MemberModel } from '../_models/member.model';

export const selectMembersState = createFeatureSelector<MembersState>('members');

export const selectMemberById = (MemberId: number) => createSelector(
    selectMembersState,
    membersState => membersState.entities[MemberId]
);

export const selectMembersPageLoading = createSelector(
    selectMembersState,
    membersState => membersState.listLoading
);

export const selectMembersActionLoading = createSelector(
    selectMembersState,
    membersState => membersState.actionsloading
);

export const selectLastCreatedMemberId = createSelector(
    selectMembersState,
    MembersState => MembersState.lastCreatedMemberId
);

export const selectMembersShowInitWaitingMessage = createSelector(
    selectMembersState,
    membersState => membersState.showInitWaitingMessage
);

export const selectMembersInStore = createSelector(
    selectMembersState,
    membersState => {       
        const items: MemberModel[] = [];
        //console.log("MembersState======",membersState)
        each(membersState.entities, element => {
            items.push(element);
        });        
        const httpExtension = new HttpExtenstionsModel();
        //const result: MemberModel[] = httpExtension.sortArray(items, MembersState.lastQuery.sortField, MembersState.lastQuery.sortOrder);
        return new QueryResultsModel(items, membersState.totalCount, '');
    }
);
