// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { OrdersState } from '../_reducers/order.reducers';
import { OrderModel } from '../_models/order.model';

export const selectOrdersState = createFeatureSelector<OrdersState>('order');

export const selectOrderById = (OrderId: string) => createSelector(
    selectOrdersState,
    OrdersState => OrdersState.entities[OrderId]
);

export const selectOrdersPageLoading = createSelector(
    selectOrdersState,
    OrdersState => OrdersState.listLoading
);

export const selectOrdersActionLoading = createSelector(
    selectOrdersState,
    customersState => customersState.actionsloading
);

export const selectOrdersPageLastQuery = createSelector(
    selectOrdersState,
    OrdersState => OrdersState.lastQuery
);

export const selectLastCreatedOrderId = createSelector(
    selectOrdersState,
    OrdersState => OrdersState.lastCreatedOrderId
);

export const selectOrdersInitWaitingMessage = createSelector(
    selectOrdersState,
    OrdersState => OrdersState.showInitWaitingMessage
);

export const selectOrdersInStore = createSelector(
    selectOrdersState,
    OrdersState => {
        const items: OrderModel[] = [];
        each(OrdersState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        //const result: OrderModel[] = httpExtension.sortArray(items, OrdersState.lastQuery.sortField, OrdersState.lastQuery.sortOrder);
        return new QueryResultsModel(items, OrdersState.totalCount, '');
    }
);

export const selectHasOrdersInStore = createSelector(
    selectOrdersInStore,
    queryResult => {
        if (!queryResult.resultCount) {
            return false;
        }

        return true;
    }
);
