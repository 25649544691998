import { QueryParamsModel } from '../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { MemberService } from '../_services';
// State
import { AppState } from '../../reducers';
// Actions
import {
    MemberActionTypes,
    MembersPageRequested,
    MembersPageLoaded,
    ManyMembersDeleted,
    OneMemberDeleted,
    MemberActionToggleLoading,
    MembersPageToggleLoading,
    MemberUpdated,
    MembersStatusUpdated,
    MemberCreated,
    MemberOnServerCreated
} from '../_actions/member.actions';
import { of } from 'rxjs';
//console.log('check --- 3')
@Injectable()
export class MemberEffects {
    showPageLoadingDistpatcher = new MembersPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new MemberActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new MemberActionToggleLoading({ isLoading: false });

    
    @Effect()
    loadMembersPage$ = this.actions$.pipe(
        ofType<MembersPageRequested>(MemberActionTypes.MembersPageRequested),
        mergeMap(( { payload } ) => {
            
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.MembersService.findMembers(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            //console.log('çontrol here')
            const result: QueryResultsModel = response[0].output;
            const lastQuery: QueryParamsModel = response[1];            
            const pageLoadedDispatch = new MembersPageLoaded({
                Members: result.list,
                totalCount: result.resultCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteMember$ = this.actions$
        .pipe(
            ofType<OneMemberDeleted>(MemberActionTypes.OneMemberDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.MembersService.deleteMember(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteMembers$ = this.actions$
        .pipe(
            ofType<ManyMembersDeleted>(MemberActionTypes.ManyMembersDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.MembersService.deleteMembers(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateMember$ = this.actions$
        .pipe(
            ofType<MemberUpdated>(MemberActionTypes.MemberUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.MembersService.updateMember(payload.Member);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateMembersStatus$ = this.actions$
        .pipe(
            ofType<MembersStatusUpdated>(MemberActionTypes.MembersStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.MembersService.updateStatusForMember(payload.Members, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createMember$ = this.actions$
        .pipe(
            ofType<MemberOnServerCreated>(MemberActionTypes.MemberOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.MembersService.createMember(payload.Member).pipe(
                    tap(res => {
                        this.store.dispatch(new MemberCreated({ Member: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private MembersService: MemberService, private store: Store<AppState>) { }
}
