
// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, delay } from 'rxjs/operators';
import { fromEvent, merge, Observable, of, Subscription } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
// UI
import { SubheaderService } from '../../../../../../core/_base/layout';
// CRUD
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../../../core/_base/crud';
// Services and Models
import {
	BNModel,
	BNDataSource,
	BNPageRequested,
	OneBNDeleted,
	ManyBNDeleted,
	BNStatusUpdated,
	selectBNPageLastQuery,
  BNService
} from '../../../../../../core/e-commerce';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'kt-bn-list',
  templateUrl: './bn-list.component.html',
  styleUrls: ['./bn-list.component.scss']
})
export class BnListComponent implements OnInit ,OnDestroy {
	// Table fields
	dataSource: BNDataSource;
   data: any[] = [];
   

	displayedColumns = ['Title','Text','Url', 'actions'];
	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild('sort1', {static: true}) sort: MatSort;
	// Filter fields
	@ViewChild('searchInput', {static: true}) searchInput: ElementRef;
	filterStatus = '';
	filterCondition = '';
	lastQuery: QueryParamsModel;
	// Selection
	selection = new SelectionModel<BNModel>(true, []);
	BNResult: BNModel[] = [];
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param dialog: MatDialog
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param store: Store<AppState>
	 */
	constructor(public dialog: MatDialog,
		           private activatedRoute: ActivatedRoute,
				   private router: Router,
				   private translate: TranslateService,
		           private subheaderService: SubheaderService,
               private BNervice:BNService,
		           private layoutUtilsService: LayoutUtilsService,
		           private store: Store<AppState>) { }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {

    // this.BNervice.findBN({filter: {},
    //   sortOrder: 'asc', // asc || desc
    //   sortField: 'id',
    //   page: 1,
    //   pageLimit: 10,
    //   type: 'ad',
    //   reqBy:'admin'}).subscribe(res=>{
    //     //console.log('The data has veen set')
    //   this.data = res.output.list;
    // })
		// If the user changes the sort order, reset back to the first page.
		const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		this.subscriptions.push(sortSubscription);

		/* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
		const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
			tap(() => this.loadBNList())
		)
		.subscribe();
		this.subscriptions.push(paginatorSubscriptions);

		//Filtration, bind to searchInput
		const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
			debounceTime(150),
			distinctUntilChanged(),
			tap(() => {
				this.paginator.pageIndex = 0;
				this.loadBNList();
			})
		)
		.subscribe();
		this.subscriptions.push(searchSubscription);

		// Set title to page breadCrumbs
		this.subheaderService.setTitle('BN');

		// Init DataSource
		this.dataSource = new BNDataSource(this.store);
	
		const entitiesSubscription = this.dataSource.entitySubject.pipe(
			skip(1),
			distinctUntilChanged()).subscribe(res => {
			this.BNResult = res;
		});
    //console.log('The data source is',this.dataSource)
		this.subscriptions.push(entitiesSubscription);
		const lastQuerySubscription = this.store.pipe(select(selectBNPageLastQuery)).subscribe(res => this.lastQuery = res);
		// Load last query from store
		this.subscriptions.push(lastQuerySubscription);

		// Read from URL itemId, for restore previous state
		const routeSubscription = this.activatedRoute.queryParams.subscribe(params => {
			if (params.id) {
				this.restoreState(this.lastQuery, params.id);
			}

			// First load
			of(undefined).pipe(delay(1000)).subscribe(() => { // Remove this line, just loading imitation
				this.loadBNList();
			}); // Remove this line, just loading imitation
		});
		this.subscriptions.push(routeSubscription);
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	/**
	 * Load BN List
	 */
	loadBNList() {
		this.selection.clear();
		const queryParams = new QueryParamsModel(
			this.filterConfiguration(),
			this.sort.direction,
			this.sort.active,
			this.paginator.pageIndex,
			this.paginator.pageSize,
			
		);
		// Call request from server
		this.store.dispatch(new BNPageRequested({ page: queryParams }));
		this.selection.clear();
	}

	/**
	 * Returns object for filter
	 */
	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;
		filter.keyWord = searchText;
		return filter;
	}

	/**
	 * Restore state
	 *
	 * @param queryParams: QueryParamsModel
	 * @param id: number
	 */
	restoreState(queryParams: QueryParamsModel, id: number) {

		if (!queryParams.filter) {
			return;
		}

		if ('condition' in queryParams.filter) {
			this.filterCondition = queryParams.filter.condition.toString();
		}

		if ('status' in queryParams.filter) {
			this.filterStatus = queryParams.filter.status.toString();
		}

		if (queryParams.filter.model) {
			this.searchInput.nativeElement.value = queryParams.filter.model;
		}
	}


	deleteBN(_item: BNModel) {
		const _title: string = this.translate.instant('Delete Tag !');
		const _description: string = this.translate.instant('Do you really want to delete this tag ? This process can not be undone.');
		const _waitDesciption: string = this.translate.instant('Deleting tag... ');
		const _deleteMessage = this.translate.instant('The tag is successfully deleted !');

		const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}

			this.store.dispatch(new OneBNDeleted({ id: _item.id }));
			this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
		});
	}

	
	// /**
	//  * Update status dialog
	//  */
	// updateStatusForBN(_item: BNModel) {
	// 	const _title = 'Update status for selected Business';
	// 	const _updateMessage = 'Status has been updated for selected Business';
	// 	const _statuses = [{ value: false, text: 'Active' }, { value: true, text: 'Block' }];
	// 	const _messages = [];

		
	// 	_messages.push({
	// 		text: `${_item.eventname}`,
	// 		id: _item.id.toString(),
	// 		status: _item.active,
	// 		statusTitle: this.getItemStatusString(_item.active),
	// 		statusCssClass: this.getItemCssClassByStatus(_item.active)
	// 	});

	// 	const dialogRef = this.layoutUtilsService.updateStatusForEntities(_title, _statuses, _messages);
	// 	dialogRef.afterClosed().subscribe(res => {
	// 		if (!res) {
	// 			this.selection.clear();
	// 			return;
	// 		}

	// 		this.store.dispatch(new BNStatusUpdated({
	// 			status: res,
	// 			BN: _item.id
	// 		}));
	// 		this.loadBNList()
	// 		this.layoutUtilsService.showActionNotification(_updateMessage, MessageType.Update);
	// 		this.selection.clear();
	// 	});
	// }

	/**
	 * Redirect to edit page
	 *
	 * @param id: any
	 */
	editBN(index) {
		//this.router.navigate(['../BN/edit', id], { relativeTo: this.activatedRoute });
		this.router.navigateByUrl('/material/form-controls/manage-BN/'+index);
	}

	createBN() {
		this.router.navigateByUrl('/ecao/BN/add');
	}

	/**
	 * Check all rows are selected
	 */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.BNResult.length;
		return numSelected === numRows;
	}

	/**
	 * Selects all rows if they are not all selected; otherwise clear selection
	 */
	masterToggle() {
		if (this.isAllSelected()) {
			this.selection.clear();
		} else {
			this.BNResult.forEach(row => this.selection.select(row));
		}
	}

	/* UI */
	/**
	 * Returns status string
	 *
	 * @param status: number
	 */
	getItemStatusString(status: boolean = false): string {
		switch (status) {
			case false:
				return 'Active';
			case true:
				return 'Blocked';
		}
		return '';
	}

	/**
	 * Returns CSS Class by status
	 *
	 * @param status: number
	 */
	getItemCssClassByStatus(status: boolean = false): string {
		switch (status) {
			case false:
				return 'success';
			case true:
				return 'danger';
		}
		return '';
	}

	/**
	 * Rerurns condition string
	 *
	 * @param condition: number
	 */
	getItemConditionString(condition: number = 0): string {
		switch (condition) {
			case 0:
				return 'New';
			case 1:
				return 'Used';
		}
		return '';
	}

	/**
	 * Returns CSS Class by condition
	 *
	 * @param condition: number
	 */
	getItemCssClassByCondition(condition: number = 0): string {
		switch (condition) {
			case 0:
				return 'accent';
			case 1:
				return 'primary';
		}
		return '';
	}
}
