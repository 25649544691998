// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { TagsState } from '../_reducers/tags.reducers';
import { TagsModel } from '../_models/tags.model';

export const selectTagsState = createFeatureSelector<TagsState>('tags');

export const selectTagsById = (productId: string) => createSelector(
    selectTagsState,
    TagsState => TagsState.entities[productId]
);

export const selectTagsPageLoading = createSelector(
    selectTagsState,
    TagsState => TagsState.listLoading
);

export const selectTagsActionLoading = createSelector(
    selectTagsState,
    customersState => customersState.actionsloading
);

export const selectTagsPageLastQuery = createSelector(
    selectTagsState,
    TagsState => TagsState.lastQuery
);

export const selectLastCreatedTagsId = createSelector(
    selectTagsState,
    TagsState => TagsState.lastCreatedTagsId
);

export const selectTagsInitWaitingMessage = createSelector(
    selectTagsState,
    TagsState => TagsState.showInitWaitingMessage
);

export const selectTagsInStore = createSelector(
    selectTagsState,
    TagsState => {
        //console.log('The tag state is',TagsState,selectTagsState)
        const items: TagsModel[] = [];
        each(TagsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        //const result: ProductModel[] = httpExtension.sortArray(items, TagsState.lastQuery.sortField, TagsState.lastQuery.sortOrder);
        return new QueryResultsModel(items, TagsState.totalCount, '');
    }
);

export const selectHasTagsInStore = createSelector(
    selectTagsInStore,
    queryResult => {
        if (!queryResult.resultCount) {
            return false;
        }

        return true;
    }
);
