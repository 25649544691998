import { BaseModel } from '../../_base/crud';

export class MemberModel extends BaseModel {

	category:string
	contentDoc:string
	contentImage:string
	id:string
	name:string
	status:number
	active:boolean
	//availiablity:string
	contentUrl:string
	videoUrl:string
	createdAt:string


	clear() {
		this.id = ""
		this.name = ""
		this.createdAt = ""
		this.category = ""
		this.status = 0
		this.contentDoc = ""
		this.contentUrl=""
		this.contentImage=""
		//this.availiablity=""
		this.videoUrl=""
		this.active = false
	}
}





