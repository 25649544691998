// NGRX
import { Action } from '@ngrx/store';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { TagsModel } from '../_models/Tags.model';
import { Update } from '@ngrx/entity';

export enum TagsActionTypes {
    TagsOnServerCreated = '[Edit Tags Component] Tags On Server Created',
    TagsCreated = '[Edit Tags Component] Tags Created',
    TagsUpdated = '[Edit Tags Component] Tags Updated',
    TagsStatusUpdated = '[Tags List Page] Tags Status Updated',
    OneTagsDeleted = '[Tags List Page] One Tags Deleted',
    ManyTagsDeleted = '[Tags List Page] Many Selected Tags Deleted',
    TagsPageRequested = '[Tags List Page] Tags Page Requested',
    TagsPageLoaded = '[Tags API] Tags Page Loaded',
    TagsPageCancelled = '[Tags API] Tags Page Cancelled',
    TagsPageToggleLoading = '[Tags] Tags Page Toggle Loading',
    TagsActionToggleLoading = '[Tags] Tags Action Toggle Loading'
}

export class TagsOnServerCreated implements Action {
    readonly type = TagsActionTypes.TagsOnServerCreated;
    constructor(public payload: { Tags: TagsModel }) { }
}

export class TagsCreated implements Action {
    readonly type = TagsActionTypes.TagsCreated;
    constructor(public payload: { Tags: TagsModel }) { }
}

export class TagsUpdated implements Action {
    readonly type = TagsActionTypes.TagsUpdated;
    constructor(public payload: {
        partialTags: Update<TagsModel>, // For State update
        Tags: TagsModel // For Server update (through service)
    }) { }
}

export class TagsStatusUpdated implements Action {
    readonly type = TagsActionTypes.TagsStatusUpdated;
    constructor(public payload: {
        Tags: string,
        status: boolean
    }) { }
}

export class OneTagsDeleted implements Action {
    readonly type = TagsActionTypes.OneTagsDeleted;
    constructor(public payload: { id: string }) {}
}

export class ManyTagsDeleted implements Action {
    readonly type = TagsActionTypes.ManyTagsDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class TagsPageRequested implements Action {
    readonly type = TagsActionTypes.TagsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class TagsPageLoaded implements Action {
    readonly type = TagsActionTypes.TagsPageLoaded;
    constructor(public payload: { Tags: TagsModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class TagsPageCancelled implements Action {
    readonly type = TagsActionTypes.TagsPageCancelled;
}

export class TagsPageToggleLoading implements Action {
    readonly type = TagsActionTypes.TagsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class TagsActionToggleLoading implements Action {
    readonly type = TagsActionTypes.TagsActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type TagsActions = TagsOnServerCreated
| TagsCreated
| TagsUpdated
| TagsStatusUpdated
| OneTagsDeleted
| ManyTagsDeleted
| TagsPageRequested
| TagsPageLoaded
| TagsPageCancelled
| TagsPageToggleLoading
| TagsActionToggleLoading;
