// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatSnackBar, MatDialog } from '@angular/material';
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, delay, take } from 'rxjs/operators';
import { fromEvent, merge, Subscription, of } from 'rxjs';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store, ActionsSubject } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
// CRUD
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../../../core/_base/crud';
// Services and Models
import { OrderModel, OrdersDataSource, OrdersPageRequested, OneOrderDeleted, ManyOrdersDeleted, OrdersStatusUpdated } from '../../../../../../core/e-commerce';
import { Router } from '@angular/router';
// Components
//import { CustomerEditDialogComponent } from '../customer-edit/customer-edit.dialog.component';

@Component({
	selector: 'kt-orders-list',
	templateUrl: './orders-list.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrdersListComponent implements OnInit, OnDestroy {
	// Table fields
	dataSource: OrdersDataSource;
	displayedColumns = ['name','status', 'actions'];
	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild('sort1', {static: true}) sort: MatSort;
	// Filter fields
	@ViewChild('searchInput', {static: true}) searchInput: ElementRef;
	filterStatus = '';
	filterType = '';
	// Selection
	selection = new SelectionModel<OrderModel>(true, []);
	OrdersResult: OrderModel[] = [];
	// Subscriptions
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param dialog: MatDialog
	 * @param snackBar: MatSnackBar
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 */
	constructor(
		public dialog: MatDialog,
		public snackBar: MatSnackBar,
		private layoutUtilsService: LayoutUtilsService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private router :Router
	) { }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		// If the user changes the sort order, reset back to the first page.
		const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		this.subscriptions.push(sortSubscription);

		/* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
		const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
			tap(() => this.loadOrdersList())
		)
		.subscribe();
		this.subscriptions.push(paginatorSubscriptions);

		// Filtration, bind to searchInput
		const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
			// tslint:disable-next-line:max-line-length
			debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
			distinctUntilChanged(), // This operator will eliminate duplicate values
			tap(() => {
				this.paginator.pageIndex = 0;
				this.loadOrdersList();
			})
		)
		.subscribe();
		this.subscriptions.push(searchSubscription);

		// Init DataSource
		this.dataSource = new OrdersDataSource(this.store);
		const entitiesSubscription = this.dataSource.entitySubject.pipe(
			skip(1),
			distinctUntilChanged()
		).subscribe(res => {
			this.OrdersResult = res;
		});
		this.subscriptions.push(entitiesSubscription);
		// First load
		of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
			this.loadOrdersList();
		}); // Remove this line, just loading imitation
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	/**
	 * Load Orders List from service through data-source
	 */
	loadOrdersList() {
		this.selection.clear();
		const queryParams = new QueryParamsModel(
			this.filterConfiguration(),
			this.sort.direction,
			this.sort.active,
			this.paginator.pageIndex,
			this.paginator.pageSize
		);
		// Call request from server
		this.store.dispatch(new OrdersPageRequested({ page: queryParams }));
		this.selection.clear();
	}

	/**
	 * Returns object for filter
	 */
	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;

		filter.keyWord = searchText;
		if (!searchText) {
			return filter;
		}

		
		return filter;
	}

	/** ACTIONS */
	/**
	 * Delete Order
	 *
	 * @param _item: OrderModel
	 */
	deleteOrder(_item: OrderModel) {
		const _title: string = this.translate.instant('Delete Content !');
		const _description: string = this.translate.instant('Do you really want to delete this content ? This process can not be undone.');
		const _waitDesciption: string = this.translate.instant('Deleting content  category... ');
		const _deleteMessage = this.translate.instant('The content category is successfully deleted !');

		const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}

			this.store.dispatch(new OneOrderDeleted({ id: _item.id }));
			this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
		});
	}

	// /**
	//  * Delete selected Orders
	//  */
	// // deleteOrders() {
	// // 	const _title: string = this.translate.instant('ECOMMERCE.OrderS.DELETE_Order_MULTY.TITLE');
	// // 	const _description: string = this.translate.instant('ECOMMERCE.OrderS.DELETE_Order_MULTY.DESCRIPTION');
	// // 	const _waitDesciption: string = this.translate.instant('ECOMMERCE.OrderS.DELETE_Order_MULTY.WAIT_DESCRIPTION');
	// // 	const _deleteMessage = this.translate.instant('ECOMMERCE.OrderS.DELETE_Order_MULTY.MESSAGE');

	// // 	const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
	// // 	dialogRef.afterClosed().subscribe(res => {
	// // 		if (!res) {
	// // 			return;
	// // 		}

	// // 		const idsForDeletion: number[] = [];
	// // 		for (let i = 0; i < this.selection.selected.length; i++) {
	// // 			idsForDeletion.push(this.selection.selected[i].id);
	// // 		}
	// // 		this.store.dispatch(new ManyOrdersDeleted({ ids: idsForDeletion }));
	// // 		this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
	// // 		this.selection.clear();
	// // 	});
	// // }

	// /**
	//  * Fetch selected Orders
	//  */
	// fetchOrders() {
	// 	const messages = [];
	// 	this.selection.selected.forEach(elem => {
	// 		messages.push({
	// 			text: `${elem.lastName}, ${elem.firstName}`,
	// 			id: elem.id.toString(),
	// 			status: elem.isBlock
	// 		});
	// 	});
	// 	this.layoutUtilsService.fetchElements(messages);
	// }

	// /**
	//  * Show UpdateStatuDialog for selected Orders
	//  */
	// updateStatusForOrders(_item: OrderModel) {
	// 	const _title = this.translate.instant('ECOMMERCE.OrderS.UPDATE_STATUS.TITLE');
	// 	const _updateMessage = this.translate.instant('ECOMMERCE.OrderS.UPDATE_STATUS.MESSAGE');
	// 	const _statuses = [{ value: false, text: 'Active'}, { value: true, text: 'Block' }];
	// 	const _messages = [];
	// 		_messages.push({
	// 			text: `${_item.lastName}, ${_item.firstName}`,
	// 			id: _item.id.toString(),
	// 			status: _item.isBlock,
	// 			statusTitle: this.getItemStatusString(_item.isBlock),
	// 			statusCssClass: this.getItemCssClassByStatus(_item.isBlock)
	// 		});
		

	// 	const dialogRef = this.layoutUtilsService.updateStatusForEntities(_title, _statuses, _messages);
	// 	dialogRef.afterClosed().subscribe(res => {
	// 		if (!res) {
	// 			return;
	// 		}
			
	// 		this.store.dispatch(new OrdersStatusUpdated({
	// 			status: res,
	// 			Orders: _item.id
	// 		}));

	// 		this.layoutUtilsService.showActionNotification(_updateMessage, MessageType.Update, 10000, true, true);
	// 	});
	// }

	/**
	 * Show add Order dialog
	 */
	// addOrder() {
	// 	const newOrder = new OrderModel();
	// 	newOrder.clear(); // Set all defaults fields
	// 	this.editOrder(newOrder);
	// }

	/**
	 * Show Edit Order dialog and save after success close result
	 * @param Order: OrderModel
	 */
	// editOrder(Order: OrderModel) {
	// 	let saveMessageTranslateParam = 'ECOMMERCE.OrderS.EDIT.';
	// 	saveMessageTranslateParam += Order.id != '' ? 'UPDATE_MESSAGE' : 'ADD_MESSAGE';
	// 	const _saveMessage = this.translate.instant(saveMessageTranslateParam);
	// 	const _messageType = Order.id != '' ? MessageType.Update : MessageType.Create;
	// 	const dialogRef = this.dialog.open(OrderEditDialogComponent, { data: { Order } });
	// 	dialogRef.afterClosed().subscribe(res => {
	// 		if (!res) {
	// 			return;
	// 		}

	// 		this.layoutUtilsService.showActionNotification(_saveMessage, _messageType);
	// 		this.loadOrdersList();
	// 	});
	// }

	/**
	 * Check all rows are selected
	 */
	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.OrdersResult.length;
		return numSelected === numRows;
	}

	/**
	 * Toggle all selections
	 */
	masterToggle() {
		if (this.selection.selected.length === this.OrdersResult.length) {
			this.selection.clear();
		} else {
			this.OrdersResult.forEach(row => this.selection.select(row));
		}
	}

	/** UI */
	/**
	 * Retursn CSS Class Name by status
	 *
	 * @param status: number
	 */
	getItemCssClassByStatus(status: boolean = false): string {
		switch (status) {
			case true:
				return 'danger';
			case false:
				return 'success';
		}
		return '';
	}

	/**
	 * Returns Item Status in string
	 * @param status: boolean
	 */
	getItemStatusString(status: boolean = false): string {
		switch (status) {
			case false:
				return 'Active';
			case true:
				return 'Block';
		}
		return '';
	}

	/**
	 * Returns CSS Class Name by type
	 * @param status: boolean
	 */
	getItemCssClassByType(status: number = 0): string {
		switch (status) {
			case 0:
				return 'accent';
			case 1:
				return 'primary';
			case 2:
				return '';
		}
		return '';
	}

	/**
	 * Returns Item Type in string
	 * @param status: number
	 */
	getItemTypeString(status: number = 0): string {
		switch (status) {
			case 0:
				return 'Business';
			case 1:
				return 'Individual';
		}
		return '';
	}

	clickedit(index){	
		this.router.navigateByUrl('/material/form-controls/manage-category/'+index);
	}
}
