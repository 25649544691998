import { BaseModel } from '../../_base/crud';

export class BNModel extends BaseModel {

	name: string
    id:string
	clear() {

	this.name = ''
    this.id = ''
	}
}





