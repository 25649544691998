import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
// CRUD
import { QueryResultsModel, QueryParamsModel } from '../../_base/crud';
// Services
import { TagsService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    TagsActionTypes,
    TagsPageRequested,
    TagsPageLoaded,
    ManyTagsDeleted,
    OneTagsDeleted,
    TagsPageToggleLoading,
    TagsStatusUpdated,
    TagsUpdated,
    TagsCreated,
    TagsOnServerCreated
} from '../_actions/tags.actions';
import { defer, Observable, of } from 'rxjs';

@Injectable()
export class TagsEffects {
    showPageLoadingDistpatcher = new TagsPageToggleLoading({ isLoading: true });
    showLoadingDistpatcher = new TagsPageToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new TagsPageToggleLoading({ isLoading: false });

    @Effect()
    loadTagssPage$ = this.actions$
        .pipe(
            ofType<TagsPageRequested>(TagsActionTypes.TagsPageRequested),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showPageLoadingDistpatcher);
                const requestToServer = this.TagsService.findTags(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {         
                    
                const result: QueryResultsModel = response[0].output;
                const lastQuery: QueryParamsModel = response[1];
                return new TagsPageLoaded({
                    Tags: result.list,
                    totalCount: result.resultCount,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteEvent$ = this.actions$
        .pipe(
            ofType<OneTagsDeleted>(TagsActionTypes.OneTagsDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showLoadingDistpatcher);
                    return this.TagsService.deleteEvent(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteTags$ = this.actions$
        .pipe(
            ofType<ManyTagsDeleted>(TagsActionTypes.ManyTagsDeleted),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.TagsService.deleteTags(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateTagsStatus$ = this.actions$
        .pipe(
            ofType<TagsStatusUpdated>(TagsActionTypes.TagsStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.TagsService.updateStatusForTags(payload.Tags, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateTags$ = this.actions$
        .pipe(
            ofType<TagsUpdated>(TagsActionTypes.TagsUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.TagsService.updateTags(payload.Tags);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    createTags$ = this.actions$
        .pipe(
            ofType<TagsOnServerCreated>(TagsActionTypes.TagsOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.TagsService.createTags(payload.Tags).pipe(
                    tap(res => {
                        this.store.dispatch(new TagsCreated({ Tags: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    // @Effect()
    // init$: Observable<Action> = defer(() => {
    //     const queryParams = new QueryParamsModel({});
    //     return of(new TagssPageRequested({ page: queryParams }));
    // });

    constructor(private actions$: Actions, private TagsService: TagsService, private store: Store<AppState>) { }
}
