// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../../environments/environment';


const API_PRODUCTS_URL = environment.API_URL
// Real REST API
@Injectable()
export class Widget12Service {
	

	constructor(private http: HttpClient) { }

	
    getProductgraph(queryParams): Observable<any> {
		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('admin:1234adm2'),
              'accessToken': userToken
            })
          };

		const url = API_PRODUCTS_URL + 'products-users/admin/products-graph';
		return this.http.post<any>(url , queryParams , httpOptions)
  }	
  
}
