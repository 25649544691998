export class DataTableItemModel {
    // id: number;
	// cModel: string;
	// cManufacture: string;
	// cModelYear: number;
	// cMileage: number;
	// cDescription: string;
	// cColor: string;
	// cPrice: number;
	// cCondition: number;
	// cStatus: number;
	// cVINCode: string;
	
	productCountByRetialerId:number;
	retailerId:string;
	storeName:string;
}
