import { BaseModel } from '../../_base/crud';

export class EventsModel extends BaseModel {

	active: boolean
	eventname: string
	id : string
	eventurl: string
	eventdate: string
	eventimage:string
	status : Number
	clear() {

	this.eventname = ''
	this.eventurl = ''
	this.eventdate = ''
	this.eventimage=''
	this.id = ''
	this.status = 0
	}
}





