
// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { EventsActions, EventsActionTypes } from '../_actions/events.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { EventsModel } from '../_models/events.model';

export interface EventsState extends EntityState<EventsModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastQuery: QueryParamsModel;
    lastCreatedEventsId: number;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<EventsModel> = createEntityAdapter<EventsModel>();

export const initialEventsState: EventsState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel({}),
    lastCreatedEventsId: undefined,
    showInitWaitingMessage: true
});

export function EventsReducer(state = initialEventsState, action: EventsActions): EventsState {
    //console.log('The action is',action)
    switch  (action.type) {
        case EventsActionTypes.EventsPageToggleLoading: return {
            ...state, listLoading: action.payload.isLoading, lastCreatedEventsId: undefined
        };
        case EventsActionTypes.EventsActionToggleLoading: return {
            ...state, actionsloading: action.payload.isLoading
        };
        case EventsActionTypes.EventsOnServerCreated: return {
            ...state
        };
        // case EventsActionTypes.EventsCreated: return adapter.addOne(action.payload.Events, {
        //      ...state, lastCreatedEventsId: action.payload.Events.id
        // });
        case EventsActionTypes.EventsUpdated: return adapter.updateOne(action.payload.partialEvents, state);
        case EventsActionTypes.EventsStatusUpdated: {
            const _partialEvents: Update<EventsModel>[] = [];
            //for (let i = 0; i < action.payload.Events.length; i++) {
                _partialEvents.push({
				    id: action.payload.events,
				    changes: {
                        active: action.payload.status
                    }
			    });
            //}
            return adapter.updateMany(_partialEvents, state);
        }
        case EventsActionTypes.OneEventsDeleted: return adapter.removeOne(action.payload.id, state);
        case EventsActionTypes.ManyEventsDeleted: return adapter.removeMany(action.payload.ids, state);
        case EventsActionTypes.EventsPageCancelled: return {
            ...state, listLoading: false, lastQuery: new QueryParamsModel({})
        };
        case EventsActionTypes.EventsPageLoaded:
            return adapter.addMany(action.payload.events, {
                ...initialEventsState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        default: return state;
    }
}

export const getEventstate = createFeatureSelector<EventsModel>('events');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
