export const environment = {
  production: false,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
  //  API_URL:'https://devapi.ecaoapp.org/v1/'//development
  //  API_URL:'http://localhost:4633/v1/'//development
    API_URL:'https://api.ecaoapp.org/v1/'  //-- Live -Production  
};



