export class QueryParamsModel {
	// fields
	filter: any;
	sortOrder: string; // asc || desc
	sortField: string;
	page: number;
	pageLimit: number;
	type: string;
	reqBy:string;

	// constructor overrides
	constructor(_filter: any,
		           _sortOrder: string = 'desc',
		           _sortField: string = '',
		           _pageNumber: number = 0,
				   _pageSize: number = 10,
				   _reqBy:string ='admin') {
		this.filter = _filter;
		this.sortOrder = _sortOrder;
		this.sortField = _sortField;
		this.page = _pageNumber + 1;
		this.pageLimit = _pageSize;
		this.reqBy =_reqBy
	}
}
