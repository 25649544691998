import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
// CRUD
import { QueryResultsModel, QueryParamsModel } from '../../_base/crud';
// Services
import { PartnerService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    PartnerActionTypes,
    PartnersPageRequested,
    PartnersPageLoaded,
    ManyPartnersDeleted,
    OnePartnerDeleted,
    PartnersPageToggleLoading,
    PartnersStatusUpdated,
    PartnerUpdated,
    PartnerCreated,
    PartnerOnServerCreated
} from '../_actions/partner.actions';
import { defer, Observable, of } from 'rxjs';

@Injectable()
export class PartnerEffects {
    showPageLoadingDistpatcher = new PartnersPageToggleLoading({ isLoading: true });
    showLoadingDistpatcher = new PartnersPageToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new PartnersPageToggleLoading({ isLoading: false });

    @Effect()
    loadPartnersPage$ = this.actions$
        .pipe(
            ofType<PartnersPageRequested>(PartnerActionTypes.PartnersPageRequested),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showPageLoadingDistpatcher);
                const requestToServer = this.PartnerService.findPartner(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {                
                const result: QueryResultsModel = response[0].output;
                const lastQuery: QueryParamsModel = response[1];
                return new PartnersPageLoaded({
                    partner: result.list,
                    totalCount: result.resultCount,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deletePartner$ = this.actions$
        .pipe(
            ofType<OnePartnerDeleted>(PartnerActionTypes.OnePartnerDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showLoadingDistpatcher);
                    return this.PartnerService.deletePartner(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deletePartners$ = this.actions$
        .pipe(
            ofType<ManyPartnersDeleted>(PartnerActionTypes.ManyPartnersDeleted),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.PartnerService.deletePartners(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updatePartnersStatus$ = this.actions$
        .pipe(
            ofType<PartnersStatusUpdated>(PartnerActionTypes.PartnersStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.PartnerService.updateStatusForPartner(payload.partner, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updatePartner$ = this.actions$
        .pipe(
            ofType<PartnerUpdated>(PartnerActionTypes.PartnerUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.PartnerService.updatePartner(payload.partner);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    createPartner$ = this.actions$
        .pipe(
            ofType<PartnerOnServerCreated>(PartnerActionTypes.PartnerOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.PartnerService.createPartner(payload.Partner).pipe(
                    tap(res => {
                        this.store.dispatch(new PartnerCreated({ partner: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    // @Effect()
    // init$: Observable<Action> = defer(() => {
    //     const queryParams = new QueryParamsModel({});
    //     return of(new PartnersPageRequested({ page: queryParams }));
    // });

    constructor(private actions$: Actions, private PartnerService: PartnerService, private store: Store<AppState>) { }
}
