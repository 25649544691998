
// Services
//export { CustomersService } from './customers.service.fake'; // You have to comment this, when your real back-end is done
 export { CustomersService } from './customers.service'; // You have to uncomment this, when your real back-end is done
 export { MemberService } from './member.service';
//export { ProductsService } from './products.service.fake'; // You have to comment this, when your real back-end is done
 export { ProductsService } from './products.service'; // You have to uncomment this, when your real back-end is done
 export { EventsService } from './events.service'; // You have to uncomment this, when your real back-end is done
 export { TagsService } from './tags.service'; // You have to uncomment this, when your real back-end is done
 export { BNService } from './bn.service'; // You have to uncomment this, when your real back-end is done
 export { PartnerService } from './partner.service'; // You have to uncomment this, when your real back-end is done
 export { OrderService } from './orders.service'; // You have to uncomment this, when your real back-end is done
export { ProductRemarksService } from './product-remarks.service.fake'; // You have to comment this, when your real back-end is done
// export { ProductRemarksService }
// from './product-remarks.service'; // You have to uncomment this, when your real back-end is done
export { ProductSpecificationsService }
from './product-specifications.service.fake'; // You have to comment this, when your real back-end is done
// export { ProductSpecificationsService }
// from './product-specifications.service'; // You have to uncomment this, when your real back-end is done
