// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { MemberModel } from '../_models/member.model';

export enum MemberActionTypes {
    MemberOnServerCreated = '[Edit Member Dialog] Member On Server Created',
    MemberCreated = '[Edit Member Dialog] Member Created',
    MemberUpdated = '[Edit Member Dialog] Member Updated',
    MembersStatusUpdated = '[Member List Page] Members Status Updated',
    OneMemberDeleted = '[Members List Page] One Member Deleted',
    ManyMembersDeleted = '[Members List Page] Many Member Deleted',
    MembersPageRequested = '[Members List Page] Members Page Requested',
    MembersPageLoaded = '[Members API] Members Page Loaded',
    MembersPageCancelled = '[Members API] Members Page Cancelled',
    MembersPageToggleLoading = '[Members] Members Page Toggle Loading',
    MemberActionToggleLoading = '[Members] Members Action Toggle Loading'
}

export class MemberOnServerCreated implements Action {
    readonly type = MemberActionTypes.MemberOnServerCreated;
    constructor(public payload: { Member: MemberModel }) { }
}

export class MemberCreated implements Action {
    readonly type = MemberActionTypes.MemberCreated;
    constructor(public payload: { Member: MemberModel }) { }
}

export class MemberUpdated implements Action {
    readonly type = MemberActionTypes.MemberUpdated;
    constructor(public payload: {
        partialMember: Update<MemberModel>, // For State update
        Member: MemberModel // For Server update (through service)
    }) { }
}

export class MembersStatusUpdated implements Action {
    readonly type = MemberActionTypes.MembersStatusUpdated;
    constructor(public payload: {
        Members: string,
        status: boolean
    }) { }
}

export class OneMemberDeleted implements Action {
    readonly type = MemberActionTypes.OneMemberDeleted;
    constructor(public payload: { id: string }) {}
}

export class ManyMembersDeleted implements Action {
    readonly type = MemberActionTypes.ManyMembersDeleted;
    constructor(public payload: { ids: string[] }) {}
}

export class MembersPageRequested implements Action {
    readonly type = MemberActionTypes.MembersPageRequested;
    
    constructor(public payload: { page: QueryParamsModel }) { //console.log('check--2')}
}
}

export class MembersPageLoaded implements Action {
    readonly type = MemberActionTypes.MembersPageLoaded;
    constructor(public payload: { Members: MemberModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class MembersPageCancelled implements Action {
    readonly type = MemberActionTypes.MembersPageCancelled;
}

export class MembersPageToggleLoading implements Action {
    readonly type = MemberActionTypes.MembersPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class MemberActionToggleLoading implements Action {
    readonly type = MemberActionTypes.MemberActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type MemberActions = MemberOnServerCreated
| MemberCreated
| MemberUpdated
| MembersStatusUpdated
| OneMemberDeleted
| ManyMembersDeleted
| MembersPageRequested
| MembersPageLoaded
| MembersPageCancelled
| MembersPageToggleLoading
| MemberActionToggleLoading;

