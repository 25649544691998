// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
// Models
import { MemberModel } from '../_models/member.model';
import { environment } from '../../../../environments/environment';

const API_MemberS_URL = environment.API_URL

@Injectable()
export class MemberService {
	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

	// CREATE =>  POST: add a new Member to the server
	createMember(Member: MemberModel): Observable<MemberModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<MemberModel>(API_MemberS_URL, Member, { headers: httpHeaders});
	}

	// READ
	getAllMembers(): Observable<MemberModel[]> {
		return this.http.get<MemberModel[]>(API_MemberS_URL);
	}

	getMemberById(MemberId: number): Observable<MemberModel> {
		return this.http.get<MemberModel>(API_MemberS_URL + `/${MemberId}`);
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findMembers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// Note: Add headers if needed (tokens/bearer)		

		//console.log('check 1')
		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
          };
		const httpParams = queryParams
		const url = API_MemberS_URL + 'members/admin/list';
		return this.http.post<QueryResultsModel>(url , httpParams , httpOptions)
	}

	// UPDATE => PUT: update the Member on the server
	updateMember(Member: MemberModel): Observable<any> {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.put(API_MemberS_URL, Member, { headers: httpHeader });
	}

	// UPDATE Status
	updateStatusForMember(Members: string, status: boolean): Observable<any> {
		//const httpHeaders = this.httpUtils.getHTTPHeaders();

		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
          };
		const body = {
			userId: Members,
			isBlock: status
		};
		const url = API_MemberS_URL + 'user/admin/blockUnBlock';
		return this.http.post(url, body, httpOptions);
	}

	// DELETE => delete the Member from the server
	deleteMember(MemberId: string): Observable<MemberModel> {

		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
          };
		const body = {
			id: MemberId,
		};
		const url = `${API_MemberS_URL}`+'members/admin/delete';
		return this.http.post<MemberModel>(url , body , httpOptions);
	}

	deleteMembers(ids: string[] = []): Observable<any> {
		const url = API_MemberS_URL + '/deleteMembers';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { MemberIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
	}
}
