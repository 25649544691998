// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
// Models
import { BNModel } from '../_models/BN.model';
import { environment } from '../../../../environments/environment';
const API_BN_URL = environment.API_URL
// Real REST API
@Injectable()
export class BNService {
	lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(new QueryParamsModel({}, 'asc', '', 0, 10));

	constructor(private http: HttpClient,
		           private httpUtils: HttpUtilsService) { }

	// CREATE =>  POST: add a new BN to the server
	createBN(BN): Observable<BNModel> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<BNModel>(API_BN_URL, BN, { headers: httpHeaders });
	}

	// READ
	getAllBN(): Observable<BNModel[]> {
		return this.http.get<BNModel[]>(API_BN_URL);
	}

	getBNById(BNId: number): Observable<BNModel> {
		//return this.http.get<BNModel>(API_BN_URL + `/${BNId}`);

		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
          };
		const httpParams = {"userId": `${BNId}`}

		const url = API_BN_URL + 'user/get';
		return this.http.post<BNModel>(url , httpParams , httpOptions)
	}

	// Server should return filtered/sorted result
	findBN(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
		  };
		const httpParams = queryParams

		const url = API_BN_URL + 'bn/admin/list';
		return this.http.post<QueryResultsModel>(url , httpParams , httpOptions)
	}

	// UPDATE => PUT: update the BN on the server
	updateBN(BN: BNModel): Observable<any> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.put(API_BN_URL, BN, { headers: httpHeaders });
	}

	// UPDATE Status
	// Comment this when you start work with real server
	// This code imitates server calls
	updateStatusForBN(BN: string, status: boolean): Observable<any> {
		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
          };
		const body = {
			userId: BN,
			isBlock: status
		};
		const url = API_BN_URL + 'user/admin/blockUnBlock';
		return this.http.post(url, body, httpOptions);
	}

	deleteEvent(customerId: string): Observable<BNModel> {

		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
          };
		const body = {
			id: customerId,
		};
		const url = `${API_BN_URL}`+'BN/admin/delete';
		return this.http.post<BNModel>(url , body , httpOptions);
	}

	deleteBN(ids: number[] = []): Observable<any> {
		const url = API_BN_URL + '/delete';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { prdocutIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
	}
}
