import { BaseModel } from '../../_base/crud';

export class ProductModel extends BaseModel {

	id: string
	firstName:string
	lastName:string
	companyName:string
	email: string
	phone:number
	image: string
	type: string
	active: boolean
	status:number
	isBlock:boolean
	isNotification: boolean
	createdAt: Date

	clear() {
		this.firstName = ''
		this.lastName = ''
		this.companyName=''
		this.email =''
		this.status =1
		this.phone = 0
		this.image = ''
		this.type = 'USER'
		this.active = false
		this.isBlock = false
		this.isNotification = true
	}
}





