import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
// CRUD
import { QueryResultsModel, QueryParamsModel } from '../../_base/crud';
// Services
import { EventsService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    EventsActionTypes,
    EventsPageRequested,
    EventsPageLoaded,
    ManyEventsDeleted,
    OneEventsDeleted,
    EventsPageToggleLoading,
    EventsStatusUpdated,
    EventsUpdated,
    EventsCreated,
    EventsOnServerCreated
} from '../_actions/events.actions';
import { defer, Observable, of } from 'rxjs';

@Injectable()
export class EventsEffects {
    showPageLoadingDistpatcher = new EventsPageToggleLoading({ isLoading: true });
    showLoadingDistpatcher = new EventsPageToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new EventsPageToggleLoading({ isLoading: false });

    @Effect()
    loadEventssPage$ = this.actions$
        .pipe(
            ofType<EventsPageRequested>(EventsActionTypes.EventsPageRequested),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showPageLoadingDistpatcher);
                const requestToServer = this.EventsService.findEvents(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {                
                const result: QueryResultsModel = response[0].output;
                const lastQuery: QueryParamsModel = response[1];
                return new EventsPageLoaded({
                    events: result.list,
                    totalCount: result.resultCount,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    deleteEvent$ = this.actions$
        .pipe(
            ofType<OneEventsDeleted>(EventsActionTypes.OneEventsDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showLoadingDistpatcher);
                    return this.EventsService.deleteEvent(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteEvents$ = this.actions$
        .pipe(
            ofType<ManyEventsDeleted>(EventsActionTypes.ManyEventsDeleted),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.EventsService.deleteEvents(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateEventsStatus$ = this.actions$
        .pipe(
            ofType<EventsStatusUpdated>(EventsActionTypes.EventsStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.EventsService.updateStatusForEvents(payload.events, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateEvents$ = this.actions$
        .pipe(
            ofType<EventsUpdated>(EventsActionTypes.EventsUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.EventsService.updateEvents(payload.events);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    createEvents$ = this.actions$
        .pipe(
            ofType<EventsOnServerCreated>(EventsActionTypes.EventsOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.EventsService.createEvents(payload.Events).pipe(
                    tap(res => {
                        this.store.dispatch(new EventsCreated({ events: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    // @Effect()
    // init$: Observable<Action> = defer(() => {
    //     const queryParams = new QueryParamsModel({});
    //     return of(new EventssPageRequested({ page: queryParams }));
    // });

    constructor(private actions$: Actions, private EventsService: EventsService, private store: Store<AppState>) { }
}
