// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
// Models
import { CustomerModel } from '../_models/customer.model';
import { environment } from '../../../../environments/environment';

const API_CUSTOMERS_URL = environment.API_URL

@Injectable()
export class CustomersService {
	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

	// CREATE =>  POST: add a new customer to the server
	createCustomer(customer: CustomerModel): Observable<CustomerModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<CustomerModel>(API_CUSTOMERS_URL, customer, { headers: httpHeaders});
	}

	// READ
	getAllCustomers(): Observable<CustomerModel[]> {
		return this.http.get<CustomerModel[]>(API_CUSTOMERS_URL);
	}

	getCustomerById(customerId: number): Observable<CustomerModel> {
		return this.http.get<CustomerModel>(API_CUSTOMERS_URL + `/${customerId}`);
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findCustomers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// Note: Add headers if needed (tokens/bearer)		
		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
          };
		const httpParams = queryParams
		const url = API_CUSTOMERS_URL + 'contents/admin/list';
		return this.http.post<QueryResultsModel>(url , httpParams , httpOptions)
	}

	// UPDATE => PUT: update the customer on the server
	updateCustomer(customer: CustomerModel): Observable<any> {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.put(API_CUSTOMERS_URL, customer, { headers: httpHeader });
	}

	// UPDATE Status
	updateStatusForCustomer(customers: string, status: boolean): Observable<any> {
		//const httpHeaders = this.httpUtils.getHTTPHeaders();

		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
          };
		const body = {
			userId: customers,
			isBlock: status
		};
		const url = API_CUSTOMERS_URL + 'user/admin/blockUnBlock';
		return this.http.post(url, body, httpOptions);
	}

	// DELETE => delete the customer from the server
	deleteCustomer(customerId: string): Observable<CustomerModel> {

		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
          };
		const body = {
			id: customerId,
		};
		const url = `${API_CUSTOMERS_URL}`+'contents/admin/delete';
		return this.http.post<CustomerModel>(url , body , httpOptions);
	}

	deleteCustomers(ids: string[] = []): Observable<any> {
		const url = API_CUSTOMERS_URL + '/deleteCustomers';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { customerIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
	}
	listcategories(queryParams): Observable<any> {
		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('ecao:GhSJp[6!2npr95p'),
              'accessToken': userToken
            })
          };

		const url = API_CUSTOMERS_URL + 'categories/admin/list';
		return this.http.post<any>(url , queryParams , httpOptions)
	}
}
