// NGRX
import { Action } from '@ngrx/store';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { EventsModel } from '../_models/events.model';
import { Update } from '@ngrx/entity';

export enum EventsActionTypes {
    EventsOnServerCreated = '[Edit Events Component] Events On Server Created',
    EventsCreated = '[Edit Events Component] Events Created',
    EventsUpdated = '[Edit Events Component] Events Updated',
    EventsStatusUpdated = '[Events List Page] Events Status Updated',
    OneEventsDeleted = '[Events List Page] One Events Deleted',
    ManyEventsDeleted = '[Events List Page] Many Selected Events Deleted',
    EventsPageRequested = '[Events List Page] Events Page Requested',
    EventsPageLoaded = '[Events API] Events Page Loaded',
    EventsPageCancelled = '[Events API] Events Page Cancelled',
    EventsPageToggleLoading = '[Events] Events Page Toggle Loading',
    EventsActionToggleLoading = '[Events] Events Action Toggle Loading'
}

export class EventsOnServerCreated implements Action {
    readonly type = EventsActionTypes.EventsOnServerCreated;
    constructor(public payload: { Events: EventsModel }) { }
}

export class EventsCreated implements Action {
    readonly type = EventsActionTypes.EventsCreated;
    constructor(public payload: { events: EventsModel }) { }
}

export class EventsUpdated implements Action {
    readonly type = EventsActionTypes.EventsUpdated;
    constructor(public payload: {
        partialEvents: Update<EventsModel>, // For State update
        events: EventsModel // For Server update (through service)
    }) { }
}

export class EventsStatusUpdated implements Action {
    readonly type = EventsActionTypes.EventsStatusUpdated;
    constructor(public payload: {
        events: string,
        status: boolean
    }) { }
}

export class OneEventsDeleted implements Action {
    readonly type = EventsActionTypes.OneEventsDeleted;
    constructor(public payload: { id: string }) {}
}

export class ManyEventsDeleted implements Action {
    readonly type = EventsActionTypes.ManyEventsDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class EventsPageRequested implements Action {
    readonly type = EventsActionTypes.EventsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class EventsPageLoaded implements Action {
    readonly type = EventsActionTypes.EventsPageLoaded;
    constructor(public payload: { events: EventsModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class EventsPageCancelled implements Action {
    readonly type = EventsActionTypes.EventsPageCancelled;
}

export class EventsPageToggleLoading implements Action {
    readonly type = EventsActionTypes.EventsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class EventsActionToggleLoading implements Action {
    readonly type = EventsActionTypes.EventsActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type EventsActions = EventsOnServerCreated
| EventsCreated
| EventsUpdated
| EventsStatusUpdated
| OneEventsDeleted
| ManyEventsDeleted
| EventsPageRequested
| EventsPageLoaded
| EventsPageCancelled
| EventsPageToggleLoading
| EventsActionToggleLoading;
