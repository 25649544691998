// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// Models
import { DataTableItemModel } from '../models/datatable-item.model';

const API_DATATABLE_URL = 'http://ec2-13-234-249-108.ap-south-1.compute.amazonaws.com:8631/v1/';

@Injectable()
export class DataTableService {
	/**
	 * Service Constructor
	 *
	 * @param http: HttpClient
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Returns data from fake server
	 */
	// getAllItems(): Observable<DataTableItemModel[]> {
	// 	return this.http.get<DataTableItemModel[]>(API_DATATABLE_URL);
	// }


	getAllItems(): Observable<DataTableItemModel[]> {
		const userToken = localStorage.getItem('userdata');
		const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              Authorization: 'Basic ' + btoa('admin:1234adm2'),
              'accessToken': userToken
            })
          };

		const url = API_DATATABLE_URL + 'products-users/admin/retailerListWithProduct';
		return this.http.post<DataTableItemModel[]>(url , {} , httpOptions)
	}

}
